import { ReduxService } from 'dashboard-services';

import { basicTimeseriesActions } from 'actions/timeseries';

export const basicInitialState = {
  activeTab: undefined,

  address: undefined,
  query: undefined,

  isEditingKey: true,
}

export default (state = basicInitialState, action) => {
  switch (action.type) {
    case basicTimeseriesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case basicTimeseriesActions.CLEAR:
      return basicInitialState;
    default:
      return state;
  }
}