import { combineReducers } from 'redux';

import basicState, { basicInitialState } from './basic';
import keyState, { keyInitialState } from './key';
import listState, { listInitialState } from './list';

export const timeseriesInitialState =  { 
  basicState: basicInitialState,
  keyState: keyInitialState,
  listState: listInitialState
}

export default combineReducers({ 
  basicState, 
  keyState,
  listState
})