import { batch } from 'react-redux';

import { ActionService, AuthApi, TimeService } from 'dashboard-services';
import { loginActions } from 'primary-components';

import getApiConfig from 'api/ApiConfig';

export default (() => {
  
  const ON_CHANGE = 'ON_CHANGE_SETTINGS'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const getSettings = token => dispatch =>
    new AuthApi(dispatch(getApiConfig()))
      .getUserSettings(token, { entitlements: true })
      .withErrorHandler(() => {
        dispatch(loginActions.logOut(false))
      })
      .noFetching()
      .build()
      .call()
      .then(response => {
        batch(() => {
          dispatch(onChange(response?.settings?.definedTime || TimeService.UTC, "definedTime"))
          dispatch(onChange(response?.settings?.userName, "userName"))
          dispatch(onChange(response?.settings?.company, "company"))
        })
        return response?.settings || {}
      })

  return {
    onChange,
    ON_CHANGE,
    getSettings
  }
})()