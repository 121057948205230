import { ReduxService } from 'dashboard-services';

import { keyTimeseriesActions } from 'actions/timeseries';

export const keyInitialState = { 
  groupName: undefined,
  symbols: {},
  metadatas: {},
  columns: undefined,
  index: undefined,
}

export default (state = keyInitialState, action) => {
  switch (action.type) {
    case keyTimeseriesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case keyTimeseriesActions.UPDATE_OBJECT:
      return ReduxService.updateObject(state, { [action.name]: action.fun(state[action.name]) })
    case keyTimeseriesActions.FILL_STATE_FROM_KEY:
      return ReduxService.updateObject(state, { ...action.key })
    case keyTimeseriesActions.CLEAR:
      return keyInitialState;
    default:
      return state;
  }
}