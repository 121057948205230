import { formatTypes, ReduxService, TimeService, tsDataRangesObject, tsLastTypesObject, tsOrder } from 'dashboard-services';

import { listTimeseriesActions } from 'actions/timeseries';
import { TimeSeriesUtils } from 'utils';

export const listInitialState = {
  keys: [],
  
  csvHeaders: TimeSeriesUtils.DEFAULT_CSV_HEADERS,
  dateFormat: TimeService.BACKEND_ISO_DATE_TIME_FORMAT,
  formatType: formatTypes.CSV,
  order: tsOrder.DESC,
  shouldTranspose: "false",
  timeZone: undefined,
  
  range: tsDataRangesObject.last,
  lastTypeAmount: "500",
  lastType: tsLastTypesObject.value,
  
  fromSelected: undefined,
  from: undefined,
  fromTime: undefined,
  
  toSelected: undefined,
  to: undefined,
  toTime: undefined,
  
  sortKeys: "false",
  sortKeysBy: undefined,
  sortKeysOrder: tsOrder.ASC,
}

export default (state = listInitialState, action) => {
  switch (action.type) {
    case listTimeseriesActions.ON_CHANGE:
      return ReduxService.updateObject(state, { [action.name]: action.value })
    case listTimeseriesActions.CLEAR:
      return listInitialState;
    case listTimeseriesActions.ADD_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.add(action.key) })
    case listTimeseriesActions.REMOVE_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.remove(action.index) })
    case listTimeseriesActions.DUPLICATE_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.add(ReduxService.updateObject(state.keys[action.index]), action.index) })
    case listTimeseriesActions.REPLACE_KEY:
      return ReduxService.updateObject(state, { keys: state.keys.replace(action.key, action.index) })
    case listTimeseriesActions.ON_REORDER_KEYS:
      return ReduxService.updateObject(state, { keys: state.keys.moveItem(action.from, action.to) })
    default:
      return state;
  }
}